import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { FaRegFilePdf } from 'react-icons/fa';
export default class About extends Component {
	render() {
		let resumeData = this.props.resumeData;
		return (
			<section id="about">
			  <div className="row">

				<div className="three columns">

				  <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

				</div>

				<div className="nine columns main-col">

				  <h2>About Me</h2>
				  <p>
					{
						resumeData.aboutme
					}
				  </p>

				  <div className="row">

					<div className="columns contact-details">
					  <h2>Resume</h2>
					  <p>
						<Button inverted color="grey" href="files/ML_Kin_Chang_Resume.pdf"><FaRegFilePdf />Machine Learning Version </Button>
					  </p>
					  <p>
						<Button inverted color="grey" href="files/SE_Kin_Chang_Resume.pdf"><FaRegFilePdf />Software Development Version </Button>						
					  </p>					 
					</div>
				  </div>
				</div>
			  </div>
			</section>
		);
	}
}
