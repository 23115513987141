import React from "react";
import ImageGallery from 'react-image-gallery';

export default class ETrainGallery extends React.Component {
	render() {
		const images = [
			{
				original: 'images/portfolio/coffee.jpg',
				thumbnail: 'http://lorempixel.com/250/150/nature/1/',
				description: 'Click to Physically Archive our Coffee'
			},
			{
				original: 'http://lorempixel.com/1000/600/nature/2/',
				thumbnail: 'http://lorempixel.com/250/150/nature/2/'
			},
			{
				original: 'http://lorempixel.com/1000/600/nature/3/',
				thumbnail: 'http://lorempixel.com/250/150/nature/3/'
			}
		]
		return (
			<div>
			  <ImageGallery items={images} />
			</div>
		);
  }
}
