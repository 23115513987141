let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Kin Chang",
    "role": "Machine Learning and Software Engineer",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": " Modern fruitful development in AI has inspired me to become a member of the revolution. I like to learn about new technology and solve problems with Engineering solutions.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"www.linkedin.com/in/kinchang",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/ckwojai",
          "className":"fa fa-github"
        },
        {
			"name":"facebook",
			"url":"https://www.facebook.com/wilson.chang.3979",
			"className":"fa fa-facebook-square"
        }
      ],
    "aboutme":"I am a new graduate from UCLA (class of 2018) with a Bachelor Degree in Electrcial Engineering. My undergraduate studies has exposed me to extensive experience in Machine Learning, Robotic System, and C Programming. I am pursng a career in Machine Learning with EV / Software Development. For more information, view my resume or check out my projects below.",
    "address":"India",
    "website":"https://rbhatia46.github.io",
    "education":[
      {
        "UniversityName":"The LNM Insitute of Information Technology",
        "specialization":"Some specialization",
        "MonthOfPassing":"Aug",
        "YearOfPassing":"2020",
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Some University",
        "specialization":"Some specialization",
        "MonthOfPassing":"Jan",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "CompanyName":"Some Company",
        "specialization":"Some specialization",
        "MonthOfLeaving":"Jan",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/coffee.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/console.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/farmerboy.jpg"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/girl.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"I like doing sketches and I teach students how to draw on the weekend.",
        "name":"Some technical guy"
      },
      {
          "description":"I like ice-skating! The feeling of speed and the variety of moves on the ice is just so cool!",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData
