import React, { Component } from 'react';
import {Grid, Button, Icon, Image, Item, Label } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import { FaDatabase, FaCode, FaBluetooth, FaWifi, FaGithub, FaReact, FaBook, FaChartBar } from 'react-icons/fa';

import ETrainGallery from './ETrainGallery.js';
import "react-image-gallery/styles/css/image-gallery.css";
import { IoLogoNodejs, IoLogoHtml5, IoLogoJavascript } from 'react-icons/io';

import YouTube from 'react-youtube';



const paragraph = <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />;
const size150 = {
	fontSize: '140%'
};
const size200 = {
	fontSize: '150%'
};

export default class Porfolio extends Component {
	render() {
		let resumeData = this.props.resumeData;
		return (
			<section id="portfolio">
			  <div className="row">
				<div className="twelve columns collapsed">
				  <Item.Group relaxed divided>
					<Item>
					  <Grid>
						<Grid.Row>
						  <Grid.Column width={6}>
							<ETrainGallery showThumbnails={false} />								  							
						  </Grid.Column>
						  <Grid.Column width={10}>
							<Item.Content style={size150} >
							  <Item.Header style={size200} as='a' href='https://etrain.kinchang.com'>eTrain</Item.Header>
							  <Item.Meta>
								<Grid centered>
								  <Grid.Column floated='left' width={8}>
									self-motivated
								  </Grid.Column>
								  <Grid.Column floated='right' width={7}>
									Aug 2018 - Present
								  </Grid.Column>
								  
								</Grid>
							  </Item.Meta>
							  <Item.Description>
								<p>I started this MERN project on my own while working at Sony Inc. as an Engineering Support Specialist. The vision was to facilitate my daily workflow, while storing all essential information in backend database for easy access and physical archiving.</p>
								<p>Applying my React knowledge learnt from Udemmy, I was able to create a simple yet elegant frontend user interface using ReactJS and MaterialUI. Backend RESTful API was built using NodeJS and ExpressJS, while utilizing data storage with MongoDB through Mongoose.</p>
								<p>While this app is still in development, it already showed great promises that allows me to adapt to a more clear, organized, and easy workflow at Sony.</p>
								<p>Some key functionalities can be seen in the photo galary on the left.</p>
								<p> </p>
							  </Item.Description>
							  <Item.Extra>
								<Button primary floated='right' href="https://etrain.kinchang.com">
								  Visit Live
								  <Icon name='right chevron' />
								</Button>
								<Label><IoLogoNodejs /> Node</Label>						  
								<Label><FaReact /> React</Label>
								<Label><FaDatabase />Mongodb</Label>						  
								<Label>Mongoose</Label>
								<Label>Material UI</Label>								
							  </Item.Extra>						
							</Item.Content>							
						  </Grid.Column>
						</Grid.Row>
					  </Grid>

					</Item>





					
					<Item>
					  <Item.Content style={size150} >
						<Item.Header style={size200} as='a' href='https://github.com/ckwojai/EE183_JamBand'>JamBand</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  EE183 Robotic System Design
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  January - March, 2018
							</Grid.Column>
							</Grid.Row>							
						  </Grid>
						</Item.Meta>
						<Item.Description>
						  <p>This was one of the projects carried out in my final year cap-stone course. The goal was to build two instruments using ESP controllers, incoporating sensors and actuators.</p>
						  <p>One big challenge was getting the two controllers to sync up so that the two instrumens can play in harmony. We experimented with some wirelss options but ended up with a Master/Slave Serial Setp. The Slave waits inside the Arduino Loop, until the Master gives commands using Serial Communication (wired). The end result is sublime as the drumstick and buzzer are in harmony at every beat / key.</p>
						  <p>A video demo can be watched below on YouTube.</p>

						  <p> </p>
						</Item.Description>
						<Grid centered>
						  <Grid.Row>
							<Grid.Column width={10}>
							  <YouTube
								videoId="FkhUbZ_ER8M"
								/>
						  </Grid.Column>
						  </Grid.Row>
						</Grid>
						<Item.Description>
						  <div>As a side note, I was exploring Web Development at the time and came across Reveal.js, an HTML Presentation framework; and it's awesome! A step-by-step guide was thus created for this project. Check it out online
						  <a href="https://ckwojai.github.io/EE183_JamBand/documentation/documentation.html#/sec-title-slide"> here</a>
						  ! </div>
						</Item.Description>						
						<Item.Extra>
						  <Button primary floated='right' href="https://ckwojai.github.io/EE183_JamBand/index.html">
							Landing Page
							<Icon name='right chevron' />
						  </Button>
						  <Button secondary floated='right' href="https://github.com/ckwojai/EE183_JamBand">
							<FaGithub /> Github
						  </Button>						  
						  <Label><FaCode />Arduino</Label>
						  <Label><IoLogoHtml5 />HTML</Label>
						  <Label><FaBluetooth />IoT</Label>
						  <Label><FaWifi />ESP8266</Label>
						  <Label><IoLogoJavascript />RevealJs</Label>
						</Item.Extra>
					  </Item.Content>
					</Item>

					
					<Item>
					  <Item.Content style={size150}>
						<Item.Header style={size200}as='a'>IEEE Advanced Project</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  Team Lead, UCLA
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  September 2017 - June 2018
							</Grid.Column>
							</Grid.Row>							
						  </Grid>						  
						</Item.Meta>
						<Item.Description>
						  <p>This was a year-long project with IEEE during my final year at UCLA. The ultimate goal was to build a quadcopter from scratch. Being the leader of a 4-man team, I was able to guide my team through difficult time, achieve milestones along the way, and deliver a successful product (quadcopter that hovers) as a result. </p>
						  <p>This experience is truly rewarding. I had hands on experience on 1) Wireless communication using Radio Modules, 2) Sensor Fusion with IMU (Gyro and Accel), 3) Circuit and PCB Design with Eagle, and 4) Implementing Hovering Algorithm with PID controller. It teaches me to become a better team player that follows, leads, and motivates: how to carry an Engineering project from start to finish.</p>
						</Item.Description>
						<Item.Description>
						<Grid centered>
						  <Grid.Row>
							<Grid.Column width={10}>
							  <Item.Image size="huge" src='images/portfolio/PCB.png' />
						  </Grid.Column>
						  </Grid.Row>
						</Grid>
						</Item.Description>
						<Item.Description>
						  Lectures and Lab documents can be found in IEEEBruins AP page; All code is hosted on Github.
						</Item.Description>
						<Item.Extra>
						  <Button primary floated='right' href="http://advproj.ieeebruins.org/index.php/Main_Page">
							IEEEBruins AP
							<Icon name='right chevron' />
						  </Button>
						  <Button secondary floated='right' href="https://github.com/ckwojai/IEEE-Advanced-Project">
							<FaGithub /> Github
						  </Button>						  
						  <Label><FaCode />Arduino</Label>
						  <Label>Autodesk EAGLE</Label>
						  <Label>PCB Design</Label>
						</Item.Extra>
					  </Item.Content>
					</Item>

					<Item>
					  <Item.Content style={size150} >
						<Item.Header style={size200} as='a' href='https://github.com/ckwojai/2wheelcar'>2WheelCar</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  EE183 Robotic System Design
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  January - March, 2018
							</Grid.Column>
							</Grid.Row>							
						  </Grid>
						</Item.Meta>
						<Item.Description>
						  <p>Last project in my final year cap-stone course. Appling the knowledge of State Estimation and Motion Planning from lectures, we built a two-wheel car who is able to parallel park.</p>
						  <p>One challenge is deriving the state-to-measurement function and state estimation. By attending office hours and learning from MIT lectures, we derive the eqauation for 8 cases, linearize it at each state evolution, and apply Kalmen filter for accurae state estimation. </p>
						  <p>Another challenge is to find a way to show accuracy of our State Estimation and Motion Planning algorithm. A wireless communication between Matlab and the car was developed. State Estimation was handled by Matlab in our PC, which is connected to the car's local WIFI network. A http get request is sent from the PC to the car as control input. After the car moves, new sensor measurment is sent back in Json to Matlab for the next Kalmen update. The cycle then continous.</p>
						  <p>As a result, Matlab plot our state estimation in a live, continuous manner as the actual car moves; clearly demonstrate the accuracy of our system. A video demo can be viewed below.</p>
						</Item.Description>
						<Grid centered>
						  <Grid.Row>
							<Grid.Column width={10}>
							  <YouTube
								videoId="30bCeLLuHEQ"
								/>
						  </Grid.Column>
						  </Grid.Row>
						</Grid>
						<Item.Description>
						  <p>A detailed PDF documentation for the project can be found below. All code are hosted on GitHub.</p>
						</Item.Description>						
						<Item.Extra>
						  <Button primary floated='right' href="https://github.com/ckwojai/2wheelcar/blob/master/Documentation/2wheelcar.pdf">
							<FaBook />							
						 	Documentation
						  </Button>
						  <Button secondary floated='right' href="https://github.com/ckwojai/2wheelcar">
							<FaGithub /> Github
						  </Button>						  
						  <Label><FaCode />Arduino</Label>
						  <Label><FaCode />MatLab</Label>						  
						  <Label><FaBluetooth />IoT</Label>
						  <Label><FaWifi />ESP8266</Label>
						</Item.Extra>
					  </Item.Content>
					</Item>

					
					<Item>
					  <Item.Image size="medium" src='images/portfolio/masscopter.png' />
1
					  <Item.Content style={size150}>
						<Item.Header as='a'>Masscopter</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  EE183 Robotic System Design
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  March - June, 2018
							</Grid.Column>
							</Grid.Row>							
						  </Grid>
						</Item.Meta>						
						<Item.Description>
						  <p>This is the final research project in the course. We aim to design an off-center spinning mass underactuated controller to steer flying objects. A quadcopter with a rotating arm attached is used to demonstrate the principle of such controller. To approach this control problem, we first derive the Mathematical Model, followed by simulation and motor control, and finally execute the actual implementation in Crazyflie by Bitcraze.</p>
						  <p>Our Paper, Presentation and Code are all hosted on Github below.</p>
						</Item.Description>
						<Item.Extra>

						  <Button primary floated='right' href="https://github.com/Amir-Omidfar/183DB-/blob/master/final_paper.pdf">
							<FaBook />
							Paper
						  </Button>
						  <Button color="grey" floated='right' href="https://github.com/Amir-Omidfar/183DB-/blob/master/final_presentation.pdf">
							<FaChartBar />
							Prsentation
						  </Button>						  							
						  <Button secondary floated='right' href="https://github.com/Amir-Omidfar/183DB-">
							<FaGithub /> Github
						  </Button>						  
						  <Label><FaCode />Arduino</Label>
						  <Label><FaCode />Matlab</Label>
						  <Label><FaBook />Quacopter Dynamics</Label>						  
						  <Label><FaBook />Control Theory</Label>
						</Item.Extra>						
					  </Item.Content>
					</Item>
					<Item>
					  <Item.Image size="medium" src='images/portfolio/Boardkey.png' />

					  <Item.Content style={size150}>
						<Item.Header as='a'>Boardkey</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  CS M117 Computer Network
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  March - June, 2018
							</Grid.Column>
							</Grid.Row>							
						  </Grid>
						</Item.Meta>						
						<Item.Description>
						  <p>This is a project-based course focusing on wireless technology. We created an IOS application that allows the iPhone to be used as a wireless Bluetooth keyboard for macOS. We familiarize ourselves with Swift and utilize two Apple frameworks, Core Bluetooth and Core Graphics, to make this possible.</p>
						  <p>Our Report, Presentation and Code are all hosted on Github below.</p>
						</Item.Description>
						<Item.Extra>

						  <Button primary floated='right' href="https://github.com/ckwojai/BoardKey/blob/master/Documents/final_report.pdf">
							<FaBook />
							Report
						  </Button>
						  <Button color="grey" floated='right' href="https://github.com/ckwojai/BoardKey/blob/master/Documents/final_presentation.pdf">
							<FaChartBar />
							Prsentation
						  </Button>						  							
						  <Button secondary floated='right' href="https://github.com/ckwojai/BoardKey">
							<FaGithub /> Github
						  </Button>						  
						  <Label><FaCode />Swift</Label>
						  <Label><FaBluetooth />Bluetooth</Label>						  
						</Item.Extra>						
					  </Item.Content>
					</Item>
					<Item>
					  <Item.Image size="medium" src='images/portfolio/Yelpcamp.png' />

					  <Item.Content style={size150}>
						<Item.Header as='a'>YelpCamp</Item.Header>
						<Item.Meta>
						  <Grid centered>
							<Grid.Row>
							<Grid.Column floated='left' width={8}>
							  Udemy
							</Grid.Column>
							<Grid.Column floated='right' width={7}>
							  July - Sept., 2017
							</Grid.Column>
							</Grid.Row>							
						  </Grid>
						</Item.Meta>						
						<Item.Description>
						  <p>This is the last project in the Udemmy Course: The Web Developer Bootcamp by Colt Steele.</p>
						  <p>To build this site, various technologies in Web Development are utilized, including </p>
						  <p>Frontend: Templating using EJS, Layout using Bootstrap and Jquery; </p>
						  <p>Backend:  RESTful routing with Express, Database management with Mongodb and Mongoose, User Authentication with Passport </p>
						  <p>Try it out yourself below!</p>
						</Item.Description>
							  <Item.Extra>
								<Button primary floated='right' href="https://yelpcamp.kinchang.com">
								  Visit Live
								  <Icon name='right chevron' />
								</Button>
								<Label><IoLogoNodejs /> Node</Label>
								<Label><FaDatabase />Mongodb</Label>
								<Label><IoLogoJavascript />Express</Label>																
								<Label><IoLogoJavascript />Bootstrap</Label>								
								<Label><IoLogoJavascript />Passport</Label>
							  </Item.Extra>										
					  </Item.Content>
					</Item>					
				  </Item.Group>
				</div>
			  </div>
			</section>
		);
	}
}
