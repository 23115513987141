import React, { Component } from 'react';
import { FaPen, FaPhone } from 'react-icons/fa';
export default class ContactUs extends Component {
	render() {
		let resumeData = this.props.resumeData;
		return (
			<section id="contact">
			  <div className="row section-head">
				<div className="ten columns">
				  <p className="lead">
					Feel free to contact me for any work or suggestions below
				  </p>
				</div>
			  </div>
			  <div className="row">
				<aside className="eigth columns footer-widgets">
				  <div className="widget">
					<h4>
					  <a href="mailto:kinchang0811@gmail.com"><FaPen className="fa-fw" />kinchang0811@gmail.com</a>
					</h4>
					<h4>
					  <a href="tel:510-458-3511"><FaPhone className="fa-fw" />(510) 458-3511</a>						</h4>
				  </div>
				</aside>
			  </div>
			</section>
        );
	}
}
